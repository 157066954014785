/* ContactUs.css */
.contact-us {
  background-color: #ffffff;
}

.contact-us h2 {
  color: #007bff;
}

input {
  outline: 0px;
  border-radius: 0px !important;
  box-shadow: none !important;
  height: 50px;
}

textarea {
  outline: 0px;
  border-radius: 0px !important;
  box-shadow: none !important;
}
