/* BlogPost.css */

.blog-post {
  font-family: Arial, sans-serif;
  color: #333;
}

/* Hero Section */
.blog-hero {
  position: relative;
  height: 400px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.hero-overlay {
  background: rgba(0, 0, 0, 0.6); /* Dark overlay */
  padding: 20px;
  text-align: center;
}

.blog-title {
  font-size: 2.5em;
  margin: 0;
  padding: 10px;
}

/* Content Section */
.blog-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 50px auto; /* Lift content over hero */
}

.blog-description {
  font-size: 1.2em;
  margin-bottom: 20px;
  line-height: 1.6;
}

.blog-data-list {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.blog-data-item {
  font-size: 1em;
  margin: 10px 0;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
