h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Faculty Glyphic", sans-serif;
}

p,
small {
  font-family: "Inter", sans-serif;
}

a,
button {
  font-family: "Faculty Glyphic", sans-serif;
}
