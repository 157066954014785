.gallery {
  margin-top: 50px;
  text-align: center;
}

.gallery-item {
  display: inline-block;
  margin-right: 15px;
}

.gallery-item img {
  width: 250px; /* Adjust the image size */
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}
