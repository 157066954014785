.newsletter {
  background-color: #00715d;
  padding: 100px;
}

/* Media query for small screens (below 576px) */
@media (max-width: 575.98px) {
  .newsletter {
    padding: 50px;
  }
}
