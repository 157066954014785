.hero {
  background-image: url("../../assets/Images/10533889_721832277853089_1277417385195566194_o.jpg");
  background-size: cover;
  background-position: center;
  height: 95vh;
  position: relative;
  display: flex; /* Flexbox for centering */
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0); /* Dark overlay with 20% opacity */
  z-index: 1; /* Ensures the overlay is above the image but below text */
}

.hero .hero-overlay {
  position: relative; /* Use relative positioning for flexibility */
  z-index: 2; /* Ensures text is above the overlay */
  padding: 20px;
  text-align: center;
  color: white; /* Ensures text is white for contrast */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 20px; /* Proper border-radius without quotes */
  max-width: 90%; /* Prevents text overflow on small screens */
  margin-top: 100px;
}
