.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* Keeps the 16:9 aspect ratio */
  height: 400px; /* Increased height */
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Ensures iframe covers the full container */
}
