/* Custom Header Styles */
.custom-header {
  background-color: #ffffff;
  padding: 10px 0;
}

.logo {
  max-width: 100%;
}

.nav-link {
  color: #000000;
  font-size: 1.1rem;
  transition: color 0.3s ease;
  padding: 8px 15px;
}

.nav-link:hover {
  color: #000000;
}

.dropdown-menu {
  background-color: #00715d;
  border: none;
  border-radius: 0px;
}

.dropdown-item {
  color: #ffffff;
}

.dropdown-item:hover {
  background-color: #ffff;
  color: #00715d;
}

.search-btn {
  color: #000000;
  border-color: #000000;
}

.search-btn:hover {
  background-color: #00715d;
  border-color: #00715d;
}

/* Show dropdown on hover */
@media (min-width: 768px) {
  .navbar-nav .dropdown:hover .dropdown-menu {
    display: block;
  }
}
